<template>
  <el-select v-model="localValue" filterable clearable placeholder="Select" class="w-100">
    <el-option v-for="jt in jobTypes" :key="jt.value" :label="jt.label" :value="jt.value" />
  </el-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      jobTypes: [{
        label: 'Part-time',
        value: 'part-time',
      }, {
        label: 'Full-time',
        value: 'full-time',
      }, {
        label: 'Project Based',
        value: 'project',
      }, {
        label: 'Hourly',
        value: 'hourly',
      }],
    }
  },

  computed: {
    localValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
