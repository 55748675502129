<template>
  <el-alert
    v-if="Object.keys(errors).length > 0"
    type="error"
    :title="title"
    :closable="false"
    center="center"
    effect="dark"
  >
    <ul class="mb-0">
      <li v-for="(errorsInField, field) in errors" :key="field">
        <i v-if="! hideErrorIcon" class="el-icon-error mr-1" />
        <span v-if="allowHtml" v-html="errorsInField[0]" />
        <span v-else v-text="getErrorMessage(errorsInField[0])" />
      </li>
    </ul>
  </el-alert>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default () {
        return {}
      },
    },
    closable: {
      type: Boolean,
      default: false,
    },
    allowHtml: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'The following is required:',
    },
    hideErrorIcon: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getErrorMessage (error) {
      return error.message || error
    } 
  },
}
</script>
