import {SKILL_LIMIT} from '~/vuex/utils/constant'
export default {
    methods: {
        hasDuplicates(data) {
            const selectedSkill = data;
            const skills = this.userSkill || this.skills
            return skills.map((skill) => {
                if (skill.proposal)
                    if (skill.skill_name.localeCompare(selectedSkill.skill_name) == 0) return true
                // if(skill.skill_name == selectedSkill.skill_name) return true
                if (skill.skill_id === selectedSkill.skill_id) return true
                return false
            });

        },
        updateSkillRating(data, userSkill) {
            if (userSkill.proposal) {
                this.updateProposalRate(userSkill, data.target)
            } else {
                this.updateSkillRate(data.target);
            }

        },
        removeSkill(skill) {
            // const skills = this.userSkill || this.skills
            if (skill.skill.proposal) {
                this.deleteProposalSkill(skill);
            } else {
                this.deleteSkill(skill);
            }
        },
        skillLimitHasError(event) {
            this.skillsLimitError = event
        },
        checkSkillLimit() {
            const skills = this.userSkill || this.skills
            this.skillsLimitError = skills.length == 0 ? true : false;
        },
        checkMaxSkill() {
            const skills = this.userSkill || this.skills
            this.skillsLimitError = skills.length == SKILL_LIMIT ? true : false;
            // console.log('skillMixin',this.userSkill.length)
            return false
        },
        checkSkillRating() {
            const skills = this.userSkill || this.skills

            this.skillsRatingError = false
            if (skills.length > 0) {
                skills.map(skill => {
                    let skillObj = JSON.parse(JSON.stringify(skill))

                    if (0 == skillObj.rating) {
                        this.skillsRatingError = true
                    }
                })
            }
        }
    }
}