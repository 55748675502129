<template>
  <dashboard>
    <el-row id="employer" :gutter="15">
      <el-col :xs="24">
        <div class="addjob">
          
          <el-card :body-style="{ padding: '0px' }">
            <h4 class="orange-text bold m-3">{{ $route.params.job_id ? 'Update' : 'Add a' }} Job Posting</h4>
            <hr>
            <el-alert
              v-show="success.title"
              :title="success.title + ' has been added successfully.'"
              type="success"
              effect="dark"
              show-icon
            />
            <div class="card-body">
              <el-form :rules="rules" :model="job.data" ref="jobForm" class="jobform">
                <el-row>
                  <el-col :span="24" class="">
                    <el-form-item label="Job Title" prop="title" :error="job.getError('title')" class="bold" :show-message="false">
                      <el-input v-model="job.data.title" maxlength="80" show-word-limit @input="job.clearError('title')" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24" class="mt-2">
                    <el-form-item prop="intro" :error="job.getError('intro')" :show-message="false">
                      <p class="mb-2 bold">Introduction</p>
                      <p class="small">A short one-paragraph description of your job post. This will appear in search results and in job listing pages.</p>
                      <el-input v-model="job.data.intro" type="textarea" :rows="3" minlength="30" maxlength="130" show-word-limit @input="job.clearError('intro')" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="12" :lg="12" class="">
                    <el-form-item label="Employment Type" prop="job_type" :error="job.getError('job_type')" class="bold" :show-message="false">
                      <job-type-select v-model="job.data.job_type" @input="job.clearError('job_type')" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <p class="title mb-3  is-required bold">
                  Remuneration (in USD) <span class="small" v-text="remuneration" />
                </p>
                <!-- <p class="small gray-text">Choose to set a price for the job or set to discuss it with the freelancer at a later time.</p> -->
                <el-row :gutter="20" class="remuneration">
                  <el-col :span="24" class="type">
                    <el-radio v-model="priceRadio" label="tbd" class="d-block mb-3">To be discussed</el-radio>
                    <el-radio v-model="priceRadio" label="fixed" class="d-block">Set fixed price</el-radio>
                    <div v-if="priceRadio === 'fixed'" class="input-group mt-2">
                      <div class="input-group-prepend">
                        <span class="input-group-text bold">US$</span>
                      </div>
                      <input v-model="pprice" v-cleave="{numeral: true,numeralThousandsGroupStyle: 'thousand'}" type="text" class="form-control">
                    </div>
                    <el-radio v-model="priceRadio" label="range" class="d-block mt-3">Set minimum and maximum price</el-radio>
                    <div v-if="priceRadio === 'range'" class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bold">US$</span>
                      </div>
                      <input v-cleave="{numeral: true,numeralThousandsGroupStyle: 'thousand'}" v-model="pmin" type="text" class="form-control" placeholder="Minimum">
                      <input v-cleave="{numeral: true,numeralThousandsGroupStyle: 'thousand'}" v-model="pmax" type="text" class="form-control" placeholder="Maximum">
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24" class="mt-2">
                    <!-- <el-form-item label="Description" prop="description" :error="job.getError('description')">
                      <el-input v-model="job.data.description" type="textarea" :rows="5" @input="job.clearError('description')" />
                    </el-form-item> -->
                    <p class="title is-required bold">
                      Description
                    </p>
                    <el-form-item prop="description" :error="job.getError('description')" :show-message="false">
                      <VueTrix v-model="job.data.description" input-id="description" :class="{ 'field-danger': characterCount > 2000 }" />
                      <span class="character-least" :class="{ 'text-danger': characterCount < 200 }">At least 200 character is required</span>
                      <span class="character-count" :class="{ 'text-danger': characterCount > 2000 }">{{ characterCount }}/2000</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="mb-4 d-block mt-4">
                  <el-col :span="24" class="mt-2">
                    <el-form-item label="Skills" prop="skill" :error="job.getError('skill')" class="is-required employer-skills mb-0 bold" :show-message="false">
                      <skills-select 
                        placeholder="Required skills"
                        :userSkill="[]" 
                        @createSkill="handleSearchSkill"
                        @createSkillProposal="handlereatedSkillProposal"
                        v-model="job.data.skill" 
                        @input="job.clearError('skills')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <div class="skill-tag d-inline-block mb-2 mr-2" :key="userSkillList.index" v-for="(userSkillList) in job.data.skill">
                      <p class="d-inline small">{{ userSkillList.skill_name }}</p>
                      <p @click="action('remove_skill', userSkillList.skill_name)" class="d-inline ml-3"><span ><i class="el-icon-delete" style="cursor:pointer"></i></span></p>
                    </div>
                    <div class="d-inline-block mb-2 mr-2" :key="userProposedSkill.index" v-for="(userProposedSkill) in job.data.job_skill_proposals">
                      <div class="skill-tag" v-if="!userProposedSkill.deleted">
                        <p class="d-inline small">{{ userProposedSkill.skill_name }}</p>
                        <p @click="action('remove_suggested_skill', userProposedSkill.skill_name)" class="d-inline ml-3"><span ><i class="el-icon-delete" style="cursor:pointer"></i></span></p>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <!-- <hr class="mb-3"> -->
                <!-- <el-row>
                  <el-col :span="24" class="mt-4">
                    <el-form-item prop="questions" :error="job.getError('questions')">
                      <p class="mb-2 bold">Add an optional screening question with a multiple-choice answer </p>
                      <p class="small">Applicants need to give the right answer to proceed with their application.</p>
                      <el-input v-model="job.data.questions" type="textarea" :rows="3" minlength="30" maxlength="130" show-word-limit @input="job.clearError('questions')" placeholder="Example: Have you worked as a Virtual Assistant before?" />
                    </el-form-item>
                    <el-form-item prop="answers" :error="job.getError('answers')" >
                      <p class="mb-2 pl-3 bold">Answers</p>
                      <p class="small pl-3">At least two answers are required and <b>you need to set one of them as being the correct answer to proceed</b>.</p>
                      
                      <div v-for="(a, index) in job.data.answers" :key="index" class="pl-3 mb-3 ">
                        <div class="input-group answers">
                          <input type="text" class="form-control" aria-label="Answer with radio button" placeholder="Write your answer here..." v-model="job.data.answers[index]">
                        </div>
                      </div>
                      <div class="text-left pl-3">
                          <a href="#" class="invite-another orange-text"
                             @click.prevent="addNewAnswerRow"><i class='el-icon-circle-plus'></i>
                              Add an answer</a>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row> -->
                <error-alert :errors="job.errors" />
                <div class="text-right buttons mt-3">
                  <el-button :loading="job.saving" type="success" @click="save">Save</el-button>
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import Dashboard from '~/components/Dashboard/Dashboard'
import { required, minLength, maxLength, minValue, maxValue } from '~/rules'
import Job from '~/models/Jobs/EmployerJob'
// import SkillsMultiSelect from '~/components/Skills/MultiSelect'
import SkillsSelect from '~/components/Skills/SkillForm.vue'
import SkillMixin from '~/components/Skills/SkillMixin'
import JobTypeSelect from '~/components/Jobs/JobTypeSelect'
import ErrorAlert from '~/components/ErrorAlert'
import VueTrix from 'vue-trix'
import Cleave from 'cleave.js'
import { prompt } from '~/helpers/handleError.js'

export default {
  components: { Dashboard, SkillsSelect, JobTypeSelect, VueTrix, ErrorAlert },

  mixins: [SkillMixin],
  
  directives: {
    cleave: {
      inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
      },
      bind(el, binding) {
        const input = el.querySelector('input')
        // @ts-ignore
        input._vCleave = new Cleave(input, binding.value)
      },
      update: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
      },
      unbind(el) {
        const input = el.querySelector('input')
        // @ts-ignore
        input._vCleave.destroy()
      }
    }
  },
  data () {
    return {
      priceRadio: '',
      pmin: '',
      pmax: '',
      fprice: '',
      pprice: '',
      rules: {
        title: [required('Title'), maxLength('Title')],
        intro: [minLength('Intro', 30)],
        job_type: [required('Job Type')],
        price: [minValue('Min Price', 0), maxValue('Min Price', 9999999)],
        price_min: [minValue('Min Price', 0), maxValue('Min Price', 9999999)],
        price_max: {
          validator: (rule, value, callback) => {
            if (value < this.job.data.price_min) {
              callback(new Error('Max price must be greater than min price'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        correct_answer: {},
        description: [required('Description')],
      },
      success: {},
      job: new Job(this.checkRouteParamsId()),
      jobTypes: [{
        value: 'full-time',
        label: 'Full-Time',
      }, {
        value: 'part-time',
        label: 'Part-Time',
      }, {
        value: 'project',
        label: 'Project Based',
      }, {
        value: 'hourly',
        label: 'Hourly',
      }],
    }
  },
  computed: {
    remuneration () {
      const jobType = this.job.data.job_type

      if (jobType === 'full-time') {
        return '- Typically for 40hrs/week'
      } else if (jobType === 'part-time') {
        return '- Typically for 20hrs/week'
      } else if (jobType === 'hourly') {
        return '- Hourly'
      } else if (jobType === 'project') {
        return '- Rate for the whole project'
      } else {
        return ''
      }
    },

    characterCount () {
      return this.stripHtmlTags(this.job.data.description).length
    },
  },

  watch: {
    'job.data.job_id' () {
      const data = this.job.data
      if (data.price_min && data.price_max) {
        this.priceRadio = 'range'
      } else if (data.price) {
        this.priceRadio = 'fixed'
      } else {
        this.priceRadio = 'tbd'
      }
    },
    pprice (newValue) {
      // if newValue is empty, set price min to 0
      if (! newValue) {
        this.job.data.price = ''
        return
      }

      // remove commas and set it to price
      this.job.data.price = parseFloat(newValue.toString().replace(/,/g, ''))
    },

    pmin (newValue) {
      // if newValue is empty, set price min to 0
      if (! newValue) {
        this.job.data.price_min = 0
        return
      }

      // remove commas and set it to price_min
      this.job.data.price_min = parseFloat(newValue.toString().replace(/,/g, ''))
    },

    pmax (newValue) {
      // if newValue is empty, set price min to 0
      if (! newValue) {
        this.job.data.price_max = 0
        return
      }

      // remove commas and set it to price_min
      this.job.data.price_max = parseFloat(newValue.toString().replace(/,/g, ''))
    },

    fprice (newValue) {
      // if newValue is empty, set price min to 0
      if (! newValue) {
        this.job.data.price_min = 0
        return
      }

      // remove commas and set it to price_min
      this.job.data.price_min = parseFloat(newValue.toString().replace(/,/g, ''))
    },

    'job.data.price': {
      handler (v) {
        if (v) {
          this.pprice = v
        }
      },
      deep: true,
      immediate: true
    },

    'job.data.price_min': {
      handler (v) {
        if (v) {
          this.fprice = v
          if (this.job.data.price_max) {
            this.pmin = this.job.data.price_min
          }
        }
      },
      deep: true,
      immediate: true
    },

    'job.data.price_max': {
      handler (v) {
        if (v) {
          this.pmax = v
        }
      },
      deep: true,
      immediate: true
    },

    priceRadio (newValue) {
      const data = this.job.data
      this.job.data.remuneration = newValue
      if (newValue === 'fixed') {
        data.price_min = null
        data.price_max = null
        data.price = null
      }
      if (newValue === 'tbd') {
        data.price_min = null
        data.price = null
      }
      if (newValue === 'range') {
        data.price = null
        data.price_max = this.pmax;
      }
    },
    'job.data.answers' (newValue) {
      if (typeof newValue === 'string') {
        this.job.data.answers = JSON.parse(newValue)
      }
    },
    'job.data.title' (v) {
      if (v) this.job.errors = {}
    },
    'job.data.job_type' (v) {
      if (v) this.job.errors = {}
    },
    'job.data.description' (v) {
      if (v) this.job.errors = {}
    }
  },

  methods: {
    checkRouteParamsId() {
      if(this.$route.params.job_id) {
        return this.$route.params.job_id - 10000;
      } else {
        return '';
      }
    },
    handleSearchSkill (s) {
      if (
        this.job.data.skill.length && 
        this.job.data.skill.find(f => f.skill_name === s.skill_name)
      ) {
        prompt({ http_code: 500, message: 'Ooops! You have already added that skill. Please select another one.' })
        return
      }
      this.job.data.skill = [...this.job.data.skill, s]
      this.job.errors = {}
    },
    handlereatedSkillProposal (p) {
      if (
        this.job.data.skill.length && 
        this.job.data.skill.find(f => f.skill_name === p.skill_name)
      ) {
        prompt({ http_code: 500, message: 'Ooops! You have already added that skill. Please select another one.' })
        return
      }
      this.job.data.skill = [...this.job.data.skill, p]
      this.job.errors = {}
    },
    action (type, id) {
      switch (type) {
        case 'remove_skill':
          this.job.data.skill = 
            this.job.data.skill.filter(d => d.skill_name !== id)
          break
        case 'remove_suggested_skill': {
          let index = this.job.data.job_skill_proposals.findIndex(f => f.skill_name === id)
          this.$set(this.job.data.job_skill_proposals, [index], { ...this.job.data.job_skill_proposals[index], deleted: true })
        }
      }
    },
    stripHtmlTags (str) {
      if (! str) {
        return false
      }

      return str.toString().replace(/<[^>]*>/g, '')
    },

    save () {
      this.job.save()
        .then(response => this.$router.push(`/employer/jobs/${response.data.data.job_id + 10000}`))
      
    },
    addNewAnswerRow() {
      this.job.data.answers.push('')
    },
  },

  mounted () {
    // this.addNewAnswerRow()
    this.priceRadio = 'tbd'
  }
}
</script>
<style lang="scss" scoped>
  .invite-another {
    font-size: 12px;
  }
  .type, .answers {
    .input-group-text, p {
      font-size: 12px;
    }
    .form-control{
      font-size: 12px;
      // padding-top: 1rem;
      // padding-bottom: 1rem;
      height: 34px;
    }
    input.form-control {
      max-width: 200px;
    }
    .form-control:focus,.form-control:active{
      border-color: coral;
      box-shadow: none;
    }
  }
  .skill-tag {
    background-color: #f7f7f7;
    padding: 4px 8px;
    border-radius: 10px;
  }
  @media (max-width: 500px) {
    .card-body {
      padding: 15px;
    }
    .character-least, .character-count {
      font-size: 10px;
    }
  }
</style>

