<template>
    <el-form class="mt-4">
        <el-row justify="center" type="flex">
            <el-col :md="24" :xs="24">
                <el-form-item>
                    <el-autocomplete
                            :fetch-suggestions="querySearch"
                            :hide-loading="false"
                            :highlight-first-item="true"
                            :select-when-unmatched="false"
                            :trigger-on-focus="false"
                            @select="handleSelect"
                            class="el-input"
                            :placeholder="placeholder"
                            popper-class="my-autocomplete"
                            ref="autocomplete"
                            v-model="searchInputField">
                        <i class="el-icon-edit el-input__icon" slot="suffix"></i>
                        <template slot-scope="{ item }">
                            <div>
                                <span class="name"
                                      v-if="!skillNotfound">{{ item.skill_name }}</span>
                                <span class="clickHere"
                                      v-else>
                                    Add "<span class="skill-not-found">{{item.skill_name}}</span>" as a new skill?
                                </span>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<script>
import axios from "axios";
import _ from 'lodash';
import {LOCAL_BASE_URL,SKILLS,SKILL_LIMIT} from "~/vuex/utils/constant";
export default {
    name: 'SkillForm',
    props: {
        userSkill: {
            type: Array
        },
        skillsLimitError: {
            type: Boolean
        },
        placeholder: {
          type: String,
          default: 'Enter a Skill',
        },
    },
    data: () => ({
        searchInputField: '',
        skillNotfound: false, // trigger the link for dialoge
    }),
    methods: {
        async querySearch(queryString, callback) {
            if (queryString.length >= 3) {

                queryString = queryString.trim();

                let skills = await this.searchSkill(queryString);

                // let results = queryString
                //     ? skills.filter(this.createFilter(queryString))
                //     : skills;
                let results = skills;

                // Display no skill found
                if (_.isEmpty(results)) {
                    this.skillNotfound = true;
                    return callback([
                        {
                            skill_name: queryString,
                            proposal: true,
                        }
                    ]);
                }
                // call callback function to return suggestions
                this.skillNotfound = false;
                callback(results);
            }
        },
        async handleSelect(skill) {
            // const skillSelection = await this.searchSkillByCategory([items]);
            let skillsLimit = [...this.userSkill];
            // console.log('skillForm', skillsLimit.length)
            if (skillsLimit.length < SKILL_LIMIT) {
                if (skill.proposal) {
                    this.$emit('createSkillProposal', skill)
                    // this.createSkillProposal(skillSelection[0])
                } else {
                    this.$emit('createSkill', skill)
                    // this.createSkill(skillSelection[0]);
                }
                this.$emit('skillLimitHasError', false)
            }
            else this.$emit('skillLimitHasError', true);
            // Show related begin here
            this.showRelated = false;
            //this.related = this.popular;
            return
        },
        searchSkill(queryString) {
            return new Promise(resolve => {
                Promise.all([
                    axios.get(`${LOCAL_BASE_URL}${SKILLS}/search?q=${queryString}`)
                ]).then(response => {
                    resolve(response[0].data.data);
                });
            });
        },
        createFilter(queryString) {
            return link => {
                return (
                    link.skill_name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
    }
}
</script>
<style scoped lang="scss">
    //skill dropdown
    .el-autocomplete-suggestion  {
        li.highlighted, li:hover {
            background-color: #E87722 !important;
            color: #fff;
        }
    }
</style>